
body {
  margin: 0;
  font-size: 14px;
  font-family: arial, "Microsoft Yahei", 微软雅黑;
  color: #333;
  -webkit-font-smoothing: antialiased;
  background-color: #f1f4f9;
}

.loading {
  text-align: center;
  font-size: 12px;
  margin-top: 100px;
}

.header {
  background-color: #FFF;
  border-bottom: 1px solid #DDD;
  margin-bottom: 16px;
  padding: 10px;
  position: fixed;
  width: 100%;
  background-color: #FFF;
  z-index: 1;
}

.main {
  padding: 20px;
}

.footer {
  text-align: center;
}

ul.side-nav {
  width: 200px;
  float: left;
  box-sizing: border-box;
  line-height: 2;
  font-weight: bold;
  position: fixed;
}

ul.side-nav a:link,
ul.side-nav a:visited{
  text-decoration: none;
  color: #3c3c3c;
  color: #3c3c3c;
}

ul.side-nav a:hover {
  color: red;
}

.content {
  padding-left: 210px;
}

.category {
  border-radius: 4px;
  /* border: 1px solid #DDD; */
  margin-bottom: 20px;
  background-color: #FFF;
}

.category-title {
  font-size: 16px;
  line-height: 48px;
  font-weight: bold;
  padding-left: 30px;
  border-bottom: 1px solid #DDD;
}

.category-content {
  padding: 10px 30px;
}

.link {
  display: flex;
  color: #3c3c3c;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  padding: 10px;
}

a.link:link {
  text-decoration: none;
}

.link-icon {
  display: block;
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.link-title {
  display: block;
  flex: 1;
}